@import "../../variables";

.BuildCityButton {
  position: absolute;
  right: 2%;
  bottom: 2%;

  transition: all 0.2s ease;

  background: #CECCCC;
  box-shadow: -41px -35px 34px rgba(157, 188, 46, 0.92), 27px 25px 37px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

  font-style: normal;
  font-weight: 500;
  font-size: calc(#{$sw} * 1.5);
  line-height: calc(#{$sw} * 1.9);
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #A3A3A4;
  justify-content: center;


  width: calc(#{$sw} * 29.1);
  height: calc(#{$sw} * 5.3);

  &Normal {
    cursor: pointer;
    background: #D4172A;
    box-shadow: -41px -35px 34px rgba(157, 188, 46, 0.92), 27px 25px 37px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: $white;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
      background: #B11528;
      box-shadow: -21px -24px 40px #92AA00, 15px 10px 49px #FF001A, -41px -35px 34px rgba(157, 188, 46, 0.92), 27px 25px 37px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
    }
  }
}
