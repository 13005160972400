@import "../../variables";

.rotate {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;

  background-color: $primary_red;

  display: flex;
  align-items: center;
  justify-content: center;

  .image {
    width: calc(#{$sw} * 83);
    height: auto;
  }
}

@media (orientation: portrait) {
  .rotate {
    display: flex;
  }
}

@media (orientation: landscape) {
  .rotate {
    display: none;
  }
}
