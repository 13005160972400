@import "../../variables";

.LoadingScreen {
  width: 100%;
  height: 100%;

  background-color: $primary_red;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 2;

  .logo {
    position: absolute;
    left: 50%;
    top: calc(#{$sw} * 4);

    transform: translateX(-50%);

    width: calc(#{$sw} * 24);
    height: calc(#{$sw} * 7.5);
  }

  .loading {
    font-family: 'TT Moscow Economy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: calc(#{$sw} * 5);
    text-align: center;
    letter-spacing: 3px;

    color: $white;

    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  .menuProgressbar {
    position: absolute;
    top: 60%;
    left: 50%;

    transform: translateX(-50%);

    width: calc(#{$sw} * 32);
    height: calc(#{$sw} * 1.6);
  }
}

@media (max-width: 960px) {
  .LoadingScreen {
    .logoMobile {
        position: absolute;
        left: 50%;
        top: 100px;

        transform: translateX(-50%);

        //width: calc(#{$sw} * 24);
        //height: calc(#{$sw} * 7.5);
    }

    .loading {
      font-weight: 700;
      font-size: 64px;
      line-height: 82px;
      text-align: center;
      letter-spacing: 3px;
    }

    .menuProgressbar {
      width: 83%;
      height: 22px;
    }
  }
}

@media (orientation: landscape) and (max-width: 960px) {
  .LoadingScreen {
    .logoMobile {
      top: 5%;
    }

    .loading {
      top: 60%;
    }

    .menuProgressbar {
      top: 70%;
    }
  }
}
