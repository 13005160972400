.progressbar {
  background: linear-gradient(135deg, #E1EDF8 0%, #EEF1F3 100%);
  box-shadow: inset -12px -10px 12px rgba(255, 255, 255, 0.750601), inset 0px 9px 18px #C9D3E2, inset 0px 2px 22px #C8D4E2;
  border-radius: 28px;
  padding: 5px;

  .progress {
    background-color: red;
    height: 100%;

    transition: all 1s ease;

    background-size: 50%;
    border-radius: 28px;
  }
}
