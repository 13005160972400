@import "../../variables";

.menuGameCard {
  box-shadow: 40px 60px 60px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: $white;
  transition: all 0.2s ease;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  //justify-content: center;
  //align-items: center;

  .menuGameCard__backgroundImage {
    width: 100%;
    height: 63%;
    border-radius: 30px 30px 0 0;
    background-size: cover;
  }

  .menuGameCard__textContainer {
    border-radius: 30px;
    background-color: $white;
    padding: 3%;

    height: 37%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    .menuGameCard__text {
      font-weight: 400;
      font-size: calc(#{$sw} * 1.7);
      text-align: center;
      color: $primary_red;
    }

    .menuGameCard__button {
      background: $primary_red;
      color: $white;

      box-shadow: -8.0423px -8.0423px 14.6224px rgba(255, 255, 255, 0.272044), -5.84894px -29.2447px 16.0846px rgba(246, 251, 255, 0.384288), -5.84894px 0px 5.84894px rgba(244, 248, 251, 0.50254), 13.8912px 15.3535px 36.5559px rgba(176, 195, 210, 0.727846);
      border-radius: 15px;

      width: 90%;
      height: 40%;
      margin-top: 5%;

      font-weight: 500;
      font-size: calc(#{$sw} * 1.5);
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      transition: all 0.2s ease;

      cursor: pointer;
    }
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 15px 19px 31px rgba(255, 255, 255, 0.01), -15px -19px 31px rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 960px) {
  .menuGameCard {
    border-radius: 11px;
    .menuGameCard__backgroundImage {
      height: 60%;
      background-position: center;
    }

    .menuGameCard__textContainer {
      .menuGameCard__text {
        //width: 70%;
        font-size: calc(#{$sw} * 1.87);
      }

      .menuGameCard__button {
        width: 75%;
        padding:  calc(#{$sw} * 1.5);
        margin-top: 2%;
        box-shadow: none;
      }
    }
  }
}
