@import "../../variables";

.dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: calc(#{$sw} * 0.8) calc(#{$sw} * 1);

  background: #FFFFFF;
  border: 2px solid #fff;
  border-radius: 14px;

  .arrow {
    display: flex;
    align-items: center;

    color: #000000;

    transition: transform 0.3s ease;
  }

  .text {
    font-weight: 450;
    font-size: calc(#{$sw} * 1.7);
    line-height: calc(#{$sw} * 2.3);
    margin-left: calc(#{$sw} * 1.6);

    color: #000000;
  }

  &:hover {
    border: 2px solid $primary_red;
    cursor: pointer;

    .text, .arrow {
      color: $primary_red;
    }
  }

  &Active {
    border: 2px solid $primary_red;
    cursor: pointer;

    .text {
      color: $primary_red;
    }

    .arrow {
      color: $primary_red;
      transform: rotate(180deg);
    }
  }
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
