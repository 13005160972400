@import "../../variables";

.menu {
  width: 100%;
  height: 100%;

  background-color: $primary_red;
  background-image: url("/images/menu/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gameChosen {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow: auto;

    .title {
      font-weight: 800;
      font-size: calc(#{$sw} * 4.1);
      line-height: calc(#{$sw} * 4.1);

      text-align: center;
      text-transform: uppercase;

      margin-top: calc(#{$sw} * 3.1);

      color: $white;
    }

    .desc {
      font-weight: 500;
      font-size: calc(#{$sw} * 1.9);
      line-height: calc(#{$sw} * 2.5);
      color: $white;
      margin-top: calc(#{$sw} * 0.7);

      text-align: center;
    }

    .desc2 {
      max-width: calc(#{$sw} * 81);
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: calc(#{$sw} * 1.5);
      line-height: calc(#{$sw} * 1.9);
      text-align: center;
      margin: calc(#{$sw} * 1.3) auto 0;
      color: $white;
    }

    .colsContainer {
      display: grid;
      width: calc(#{$sw} * 86);
      margin: calc(#{$sw} * 2.5) auto 0;

      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: calc(#{$sw} * 3);

      .col {
        background: #FFFFFF;
        border-radius: 30px;

        height: calc(#{$sw} * 22);

        padding: calc(#{$sw} * 0.9);

        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          margin-top: calc(#{$sw} * 0.7);
          font-weight: 400;
          font-size: calc(#{$sw} * 1.5);
          line-height: calc(#{$sw} * 1.9);
          text-align: center;
          color: $primary_red;
        }

        .img1 {
          width: calc(#{$sw} * 7.8);
          height:  calc(#{$sw} * 8);
        }

        .img2 {
          width: calc(#{$sw} * 8.6);
          height: calc(#{$sw} * 7.7);
        }

        .img3 {
          width: calc(#{$sw} * 6.8);
          height: calc(#{$sw} * 7.1);
        }
      }
    }

    .startGame {
      margin:  calc(#{$sw} * 4) auto 0;
      width: calc(#{$sw} * 15);
      height: calc(#{$sw} * 3.7);
      min-height: calc(#{$sw} * 3.7);

      * {
        font-size: calc(#{$sw} * 1.5);
      }
    }
  }
}

@media (max-width: 960px) {
  .menu {
    .gameChosen {
      .desc2 {
        margin: calc(#{$sw} * 0.5) auto 0;
      }

      .colsContainer {
        margin: calc(#{$sw} * 1.7) auto 0;

        .col {
          box-shadow: 6px 25px 19px -10px rgba(0, 0, 0, 0.25);
        }
      }

      .startGame {
        margin: calc(#{$sw} * 2.5) auto calc(#{$sw} * 1);
        line-height: calc(#{$sw} * 3.4);
        width: calc(#{$sw} * 27);
        height: calc(#{$sw} * 6.1);

        * {
          font-size: calc(#{$sw} * 2.7);
        }
      }
    }
  }
}
