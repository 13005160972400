@import "../../variables.scss";

.menu {
  width: 100%;
  height: 100%;

  background-image: url("/images/menu/background.jpg");
  background-color: $primary_red;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: calc(#{$sw} * 6.9);
    width: 100%;

    .gameCard {
      text-decoration: none;
      width: calc(#{$sw} * 27);
      height: calc(#{$sw} * 30);
      position: relative;
      top: -5%;

      &:not(:first-child) {
        margin-left: calc(#{$sw} * 5);
      }
    }
  }

  .aboutProject {
    //position: absolute;
    //bottom: calc(#{$sw} * 2);
    margin: auto auto calc(#{$sw} * 2);

    //left: 50%;
    //transform: translateX(-50%);

    width: calc(#{$sw} * 23);
    height: calc(#{$sw} * 3.75);

    text-decoration: none;

    svg {
      width: calc(#{$sw} * 3);
      height: calc(#{$sw} * 2.5);
      margin-right: calc(#{$sw} * 1);
    }
  }

  .gameChosen {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow: auto;

    .title {
      font-weight: 800;
      font-size: calc(#{$sw} * 4.1);
      line-height: calc(#{$sw} * 4.1);

      text-align: center;
      text-transform: uppercase;

      margin-top: calc(#{$sw} * 3.1);

      color: $white;
    }

    .desc {
      font-weight: 500;
      font-size: calc(#{$sw} * 1.9);
      line-height: calc(#{$sw} * 2.5);
      color: $white;
      margin-top: calc(#{$sw} * 0.7);

      text-align: center;
    }

    .colsContainer {
      display: grid;
      width: calc(#{$sw} * 86);
      margin: calc(#{$sw} * 2.2) auto 0;

      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: calc(#{$sw} * 3);

      .col {
        background: #FFFFFF;
        box-shadow: 40.8959px 65.4334px 65.4334px rgba(0, 0, 0, 0.18);
        border-radius: 30px;

        width: 100%;
        height: calc(#{$sw} * 31);

        padding: calc(#{$sw} * 0.9);

        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          margin-top: calc(#{$sw} * 0.7);
          font-weight: 400;
          font-size: calc(#{$sw} * 1.5);
          line-height: calc(#{$sw} * 1.9);
          text-align: center;
          color: $primary_red;
        }

        .img1 {
          width: calc(#{$sw} * 7.8);
          height: calc(#{$sw} * 8);
        }

        .img2 {
          width: calc(#{$sw} * 8.6);
          height: calc(#{$sw} * 7.7);
        }

        .img3 {
          width: calc(#{$sw} * 6.8);
          height: calc(#{$sw} * 7.1);
        }
      }
    }

    .startGame {
      margin: calc(#{$sw} * 3.8) auto calc(#{$sw} * 3.1);
      width: calc(#{$sw} * 15);
      height: calc(#{$sw} * 4.4);
    }
  }
}

@media (max-width: 960px) {
  .menu {
    .row {
      top: calc(#{$sw} * 4);

      .gameCard {
        width: calc(#{$sw} * 38.7);
        height: calc(#{$sw} * 27.7);
      }
    }

    .aboutProject {
      width: calc(#{$sw} * 40);
      height: calc(#{$sw} * 6.1);
      bottom: calc(#{$sw} * 4.4);

      * {
        font-size: calc(#{$sw} * 2.4);
        line-height: calc(#{$sw} * 3);

        svg {
          width: calc(#{$sw} * 3.6);
          height: calc(#{$sw} * 4.1);
          margin-right: calc(#{$sw} * 2.5);
        }
      }
    }
  }
}
