@import "../../variables";

.MenuButtonRed {
  flex-shrink: 0;
  font-style: normal;
  font-weight: 500;
  font-size: calc(#{$sw} * 1.3);
  line-height: calc(#{$sw} * 1.8);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: $white;

  background: #D4172A;
  box-shadow:  -2.79846px -3.49807px 10.4942px #FF6171, -23.0873px -12.5931px 23.7869px #FF495C, 18.8896px 17.4904px 25.8857px rgba(0, 0, 0, 0.4);
  border-radius: 20px;

  transition: all 0.2s ease;
  padding: 2%;

  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.5);
    background: #B11528;
    box-shadow: -2.79846px -3.49807px 10.4942px #FF6171, -23.0873px -12.5931px 23.7869px #FF495C, 18.8896px 17.4904px 25.8857px rgba(0, 0, 0, 0.4);
    border-radius: 20.9884px;
  }
}
