@import "../../variables";

.result {
  width: 100%;
  height: 100%;

  background-color: $primary_red;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;

  display: flex;
  flex-direction: column;

  overflow: auto;


  .title {
    font-weight: 800;
    font-size: calc(#{$sw} * 4.1);
    line-height: calc(#{$sw} * 4.1);

    text-align: center;
    text-transform: uppercase;

    margin-top: calc(#{$sw} * 3.1);

    color: $white;
  }

  .text {
    font-weight: 400;
    font-size: calc(#{$sw} * 1.7);
    line-height: calc(#{$sw} * 2.2);
    margin: calc(#{$sw} * 3.6) auto 0;
    text-align: center;
    max-width: calc(#{$sw} * 68);
    color: white;
  }

  .startGame {
    margin: calc(#{$sw} * 1.4) auto calc(#{$sw} * 3.1);
    width: calc(#{$sw} * 20);
    height: calc(#{$sw} * 3.7);
  }

  .rows {
    margin-top: calc(#{$sw} * 1.8);

    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
      background: #FFFFFF;
      border-radius: 20.9884px;

      width: calc(#{$sw} * 52);
      height: calc(#{$sw} * 5.9);

      display: flex;
      align-items: center;

      padding: 0 calc(#{$sw} * 3.1);

      .schoolIcon {
        width: calc(#{$sw} * 3.8);
        height: calc(#{$sw} * 3.8);
        margin-right: calc(#{$sw} * 3);
      }

      .livingIcon {
        width: calc(#{$sw} * 4.1);
        height: calc(#{$sw} * 3.8);
        margin-right: calc(#{$sw} * 3);
      }

      .workIcon {
        width: calc(#{$sw} * 4.5);
        height: calc(#{$sw} * 4.3);
        margin-right: calc(#{$sw} * 2.6);
      }

      .rowTitle {
        font-weight: 400;
        font-size: calc(#{$sw} * 2.4);
        line-height: calc(#{$sw} * 3.1);
        color: #D4172A;
      }

      .rowScore {
        font-weight: 500;
        font-size: calc(#{$sw} * 2.7);
        line-height: calc(#{$sw} * 3.3);
        text-align: right;
        text-transform: uppercase;
        color: #D4172A;
        margin-left: auto;
      }

      &:not(:first-child) {
        margin-top: calc(#{$sw} * 1.2)
      }
    }
  }
}

