@font-face {
  font-family: 'TT Moscow Economy';
  src: url("./fonts/TT Moscow Economy Regular.otf") format("opentype");
}

@font-face {
  font-family: 'TT Moscow Economy';
  font-weight: 500;
  src: url("./fonts/TT Moscow Economy Medium.otf") format("opentype");
}

@font-face {
  font-family: 'TT Moscow Economy';
  font-weight: 700;
  src: url("./fonts/TT Moscow Economy DemiBold.otf") format("opentype");
}

@font-face {
  font-family: 'TT Moscow Economy';
  font-weight: 800;
  src: url("./fonts/TT Moscow Economy Bold.otf") format("opentype");
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'TT Moscow Economy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1920px;
  width: 100vw;
  height: 100%;
  margin: 0 auto;

  display: flex;

  position: relative;
}

* {
  box-sizing: border-box;
}



@media (max-width: 960px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 961px) {
  .desktop-hide {
    display: none;
  }
}
