@import "variables";

canvas {
  margin: 0 auto;

  background-color: #545356;
  touch-action: none;
}

.selectContainer {
  position: absolute;
  top: 0;
  left: 0;
  padding-right: calc(#{$sw} * 2);
  z-index: 3;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  background-color: rgba(0,0,0,0.4);

  overflow-y: auto;

  height: 100%;

  .selectChild {
    &:first-child {
      margin-top: calc(#{$sw} * 2);
    }

    &:not(:first-child) {
      margin-top: calc(#{$sw} * 0.6);
    }

    &:last-child {
      margin-bottom: calc(#{$sw} * 2);
    }
  }
}

.buildingCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;

  padding-top: calc(#{$sw} * 1.6);

  width: 100%;

  grid-column-gap: calc(#{$sw} * 1.3);
  grid-row-gap: calc(#{$sw} * 1);
}

.buildingCard {
  width: calc(#{$sw} * 17);
  height: calc(#{$sw} * 22);

  border: 2px solid white;
  background: #FFFFFF;
  box-shadow: 4px 14px 15px rgba(0, 0, 0, 0.3);
  border-radius: 15px;

  padding:  calc(#{$sw} * 1.1) 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  h3 {
    font-weight: 500;
    font-size: calc(#{$sw} * 1.5);
    margin: 0 0 calc(#{$sw} * 1.4) 0;
    line-height: calc(#{$sw} * 1.4);
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }

  img {
    width: 100%;
    max-width: calc(#{$sw} * 11);
    max-height: calc(#{$sw} * 7.3);
    object-fit: contain;
  }

  p {
    font-weight: 400;
    font-size: calc(#{$sw} * 1.1);
    line-height: calc(#{$sw} * 1.3);
    max-width: calc(#{$sw} * 15.4);
    display: flex;
    align-items: center;
    text-align: center;
    height: calc(#{$sw} * 7.5);
  }

  &:hover {
    border: 2px solid $primary_red;
  }
}

.actionsButtons {
  display: flex;
  position: absolute;

  top: 100px;
  left: 100px;

  transform: translate(-50%, -100%);

  .button {
    background: #FFFFFF;
    box-shadow: -18px -14px 34px rgba(255, 255, 255, 0.25), 27px 25px 37px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    font-weight: 500;
    font-size: calc(#{$sw} * 1.5);
    line-height: calc(#{$sw} * 1.94);
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: calc(#{$sw} * 14.9);
    height: calc(#{$sw} * 5.2);

    border: 2px solid white;

    &:not(:first-child) {
      margin-left: calc(#{$sw} * 2);
    }

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #D4172A;
      border: 2px solid #D4172A;
      box-shadow: -18px -14px 34px rgba(255, 255, 255, 0.25), 27px 25px 37px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
    }
  }
}

.popupTry {
  background: #FFFFFF;
  border-radius: 40px 40px 40px 0;
  padding: calc(#{$sw} * 2);
  font-weight: 450;
  font-size:  calc(#{$sw} * 1.6);
  line-height: calc(#{$sw} * 2.1);
  display: flex;
  align-items: center;
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateY(-50%);

  pointer-events: none;

  transition: all 0.2s ease;

  &Hidden {
    opacity: 0;
  }
}

.buttonRoundWhiteSmall {
  background: #FFFFFF;
  box-shadow: -4px -8px 17px rgba(157, 188, 46, 0.37), 11px 12px 23px rgba(0, 0, 0, 0.19);
  border-radius: 100px;
  width:  calc(#{$sw} * 5.5);
  height: calc(#{$sw} * 5.5);

  padding: calc(#{$sw} * 1.2);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;

  &:hover {
    background-color: #e77d88;
    box-shadow: -18px -14px 34px rgba(255, 255, 255, 0.25), 27px 25px 37px rgba(0, 0, 0, 0.25), inset 0px 0px 250px rgba(212, 23, 42, 0.6);
  }

  &Disabled {
    background-color: #ADADAD;
  }
}

.buttonHome {
  position: absolute;

  top: calc(#{$sw} * 2);
  right: calc(#{$sw} * 17.5);

  cursor: pointer;
}

.buttonHint {
  position: absolute;

  top: calc(#{$sw} * 2);
  right: calc(#{$sw} * 9.7);

  cursor: pointer;
}

.buttonSound {
  position: absolute;

  cursor: pointer;

  top: calc(#{$sw} * 2);
  right: calc(#{$sw} * 2);
}

.popupBg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  background: rgba(0,0,0,0.4);

  transition: all 0.2s ease;
  opacity: 0;
  display: none;
}

.popupHint {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  background: #FFFFFF;
  box-shadow: -4px -8px 17px rgba(157, 188, 46, 0.37), 27px 24px 24px rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  color: #000000;

  width: calc(#{$sw} * 55.5);
  height: calc(#{$sw} * 34.7);

  &Big {
    width: calc(#{$sw} * 85.5);
    height: 90%;

    display: flex;
    flex-direction: column;

    .popupHintText {
      margin: calc(#{$sw} * 5);
      flex: 1;

      iframe {
        border: none;
      }
    }
  }

  &Text {
    margin: calc(#{$sw} * 4) calc(#{$sw} * 5.5) calc(#{$sw} * 3.3);
    font-weight: 400;
    font-size: calc(#{$sw} * 1.7);
    line-height: calc(#{$sw} * 2.2);
    text-align: center;
  }

  &Close {
    color: $primary_red;
    position: absolute;
    top: calc(#{$sw} * 2.7);
    right: calc(#{$sw} * 2.1);
    width: calc(#{$sw} * 1.1);
    height: calc(#{$sw} * 1.1);
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      color: #A3A3A4;
    }
  }
}
